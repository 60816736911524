import React from 'react';
import styled from 'styled-components';
import { TextField , Button, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
import { backEndAdress } from '../../variables/back-endAdress';
import moment from 'moment';
import { AppContext } from '../../App';
import { SignUpFormContext } from './SignUpForm';

const ReactForm = ({ instance, state , setState }) => {

    const { setSignUpInProgress } = React.useContext(SignUpFormContext);
    const { appState } = React.useContext(AppContext);
    const { tenant } = appState;
    const instanceId = window.location.hash.split('/')[3];

    const navigate = useNavigate();
    const form = useForm({
        defaultValues : {
            firstName : '',
            lastName : '',
            email : '',
            company : '',
            role : '',
        }
    });

    const { register, handleSubmit, formState } = form;
    const { errors } = formState;

    const onSubmit = (data) => {

        setSignUpInProgress(true); 

        const addAttendee = async () => {
            try {

                const user = {
                    firstName : data.firstName.charAt(0).toUpperCase() + data.firstName.slice(1),
                    lastName : data.lastName.charAt(0).toUpperCase() + data.lastName.slice(1),
                    email : data.email,
                    company : data.company,
                    role : data.role,
                    paymentStatus : 'pending',
                    receivedSurvey : false,
                    mailsReceived : []
                }      

                const objToSend = {
                    attendee : user, 
                    instanceId
                }

                const response = await Axios.post(`${backEndAdress}/api/instances/public/add-attendee`, objToSend);
                console.log(response.data);
                setState({...state, registeredConfirmation : true});
                setSignUpInProgress(false); 

                
            } catch (error) {
                console.log(error);
            }
        }

        addAttendee();
    }

    const rendering = () => {
        return (
            <ReactFormSC>
                <>
                    <h1>Sign-up</h1>
                    <form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={2} width={'100%'}>
                            <TextField
                                required
                                id="firstName"
                                label="First Name"
                                variant="outlined"
                                {...register('firstName',
                                    { required : 'first name is required' }
                                )}
                                error={!!errors.firstName}
                                helperText={errors.firstName?.message}
                            />
                            <TextField
                                required
                                id="lastName"
                                label="Last Name"
                                variant="outlined"
                                {...register('lastName',
                                    { 
                                        required : 'Last name is required'
                                 }
                                )}
                                error={!!errors.lastName}
                                helperText={errors.lastName?.message}
                            />
                            <TextField
                                required
                                id="email"
                                label="Email"
                                variant="outlined"
                                {...register('email', 
                                    { 
                                        required : 'Email is required', 
                                        pattern : {
                                            value : /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                            message : 'Not a valid email'
                                        }
                                    }
                                )}
                                error={!!errors.email}
                                helperText={errors.email?.message}
                            />
                            <TextField
                                required
                                id="company"
                                label="Company"
                                variant="outlined"
                                {...register('company',
                                    { required : 'Company is required' }
                                )}
                                error={!!errors.company}
                                helperText={errors.company?.message}
                            />
                            <TextField
                                required
                                id="role"
                                label="Role"
                                variant="outlined"
                                {...register('role',
                                    { required : 'Role is required' }
                                )}
                                error={!!errors.role}
                                helperText={errors.role?.message}

                            />
                            <Button variant="contained" color="primary" type="submit">Submit</Button>
                        </Stack>

                    </form>
                </>
            </ReactFormSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const ReactFormSC = styled.div`
    background-color : white;
    padding : 35px;
    box-sizing : border-box;
    max-width : 100%;
    min-width : 450px;

    h1 {
        font-size : 1.5rem;
        margin-bottom : 20px;
    }

    @media (max-width: 600px) {
        padding : 30px;
        margin : 0;
        min-width : 0;

        form {
            display : flex; 
            flex-direction : column;
            align-items : center;
            box-sizing : border-box;
        }
    }


`

export default ReactForm