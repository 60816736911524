import React from 'react';
import styled from 'styled-components';

const SurveyResponses = () => {

    const rendering = () => {
        return (
            <SurveyResponsesSC>
                <p>survey respones</p>
            </SurveyResponsesSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SurveyResponsesSC = styled.div`
    
`

export default SurveyResponses