import React from 'react';
import styled from 'styled-components';
import TrainingInfo from './TrainingInfo';
import Actions from './Actions/Actions';
import RegisteredUsers from './RegisteredUsers/RegisteredUsers';
import Header from '../Header/Header';
import MailJobs from './MailJobs/MailJobs';
import SurveyAndCertificate from './SurveyAndCertificate/SurveyAndCertificate';
import JobPreviewComponent from './JobPreview/JobPreviewComponent';
import { IndividualSessionContext } from '../IndividualSession';
import NoInstanceSelected from '../../IndividualTrainingFinal/NoInstanceSelected/NoInstanceSelected';


const Dashboard = ({ individualSession }) => {

    const { jobPreview } = React.useContext(IndividualSessionContext);

    const rendering = () => {

        return (
            <DashboardSC>
              
                <Header individualSession={individualSession}/>
                {!individualSession ? 
                    <NoInstanceSelected />

                    : 

                    <div style={{display : 'flex'}}>
                        <div style={{display :'flex',  minWidth : '340px', maxWidth : '340px', flexDirection : 'column', flex : 1}}>
                            <TrainingInfo individualSession={individualSession} />
                            <SurveyAndCertificate individualSession={individualSession}/>

                        </div>
                        <div style={{display :'flex',  minWidth : '340px', maxWidth : '340px', flexDirection : 'column', flex : 1}}>
                            <Actions individualSession={individualSession}/>
                        </div>
                        <RegisteredUsers individualSession={individualSession} />
                    </div>
                }

            </DashboardSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const DashboardSC = styled.div`
    display : flex; 
    flex-direction : column;
    flex : 1;

    .title-container {
        display : flex; 
        align-items : center;
        padding : 15px; 
        
        .title {
            font-size : 1em;
            font-weight : 500;
            color : #333;
            margin : 0;
            padding : 0;
        }

        svg {
            margin-right : 10px;
            font-size : 18px;
        }
    }





`

export default Dashboard