import React from 'react';
import styled from 'styled-components';
import AddIcon from '@mui/icons-material/Add';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { colors } from '../../variables/Colors';
const SidebarFolders = ({ templates, selectedFolder, setSelectedFolder, folders, setAddFolderToggled }) => {


    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    const rendering = () => {
        return (
            <SidebarFoldersSC>

                <div className='folders-list'>
                    {folders.map(folder => {

                        const isSelected = folder === selectedFolder;

                        return (
                            <div className='folder-title'>
                                <FolderOpenIcon sx={{ stroke : 'white', strokeWidth : 1}}/>
                                <h1>{capitalizeFirstLetter(folder)}</h1>
                            </div>
                        );
                    })}
                </div>
            </SidebarFoldersSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SidebarFoldersSC = styled.div`
    min-width : 230px;
    background-color : white;
    

    .title-and-add-option {
        display : flex;
        justify-content : space-between;
        align-items : center;
        padding : 28px;
        border-bottom : 1px solid lightgrey;
        box-sizing : border-box;
    }

    .folders-list {
        display : flex;
        flex-direction : column;

        .folder-item {

            cursor : pointer;
            &:hover {
                background-color : #f2f2f2;
            }
        }

        .selected { 
            background-color : #f2f2f2;
        }
    }

    .folder-title {
        display : flex; 
        align-items : center;
        margin-left : 20px;
        margin-top : 20px;

        &>svg {
            margin-right : 10px;
            color : ${colors.primaryBlue};

        }
    } 
`

export default SidebarFolders