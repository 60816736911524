import { Button, CircularProgress } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import TitleAndSubject from './TitleAndSubject';
import { set } from 'react-hook-form';

const SideBarMailEditor = ({ exportHtml, saveDesignFunction, state, setState, subjectLineRef, titleTemplateRef, emailEditorRef }) => {

    const { fullyLoaded, templates } = state;

    const saveFunction = () => {

        //Check if there is a design to load (Adjusting existing template); 
        const adjustingExistingDesign = state.designToLoad ? true : false;

        //Check if this is a duplicate form an existing design
        const duplicateDesign = (state.designToLoad && !state.designToLoad.mailTemplateId) ? true : false;

        //If this is adjusting an existing design
        if(adjustingExistingDesign) {
            setState({...state, saveDesignLoading : true})
            saveDesignFunction();

        } else {
            console.log('new');
            setState({
                ...state,
                saveDesignPopup : true
            })
        }
    }

    const duplicateTemplateFunction = () => {

        const newObject = {
            ...state,
            designToLoad : {
                ...state.designToLoad,
                folder : state.designToLoad.folder ? state.designToLoad.folder : 'general', 
                mailTemplateId : null, 
                _id : null,
                title : 'new title'
            }
        }

        setState({
            ...state,
            designToLoad : newObject,
            saveDesignPopup : true
        })
    }

    const rendering = () => {
        return (
            <SideBarMailEditorSC>
                <TitleAndSubject 
                    state={state} 
                    setState={setState}
                    subjectLineRef={subjectLineRef}    
                    titleTemplateRef={titleTemplateRef}
                />
                <div className='buttons'>
                    {/* <Button
                        onClick={() => initializeDesign()}
                        variant='contained'
                        color='primary'
                        disabled={!fullyLoaded}
                        size={'small'}
                    >
                        New
                    </Button> */}
                    <Button
                        onClick={duplicateTemplateFunction}
                        variant='contained'
                        color='primary'
                        disabled={!fullyLoaded}
                        size={'small'}
                    >
                        Duplicate
                    </Button>
                    <Button
                        onClick={saveFunction}
                        variant='contained'
                        color='primary'
                        disabled={!fullyLoaded}
                        size={'small'}
                    >
                        Save design
                    </Button>
                    <Button
                        onClick={() => setState({...state, selectDesignPopUp : true})}
                        variant='contained'
                        color='primary'
                        size={'small'}

                    >
                        Load design
                    </Button>

                </div>

            </SideBarMailEditorSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SideBarMailEditorSC = styled.div`
    min-height : 50px;  
    display : flex;
    justify-content : space-between;
    align-items : center;
    border-bottom : 1px solid rgba(0,0,0,0.1);

    .buttons {
        display : flex;
        justify-content : center;
        align-items : center;
        gap : 5px;
        margin-right : 15px
        
    }

    .title-template {
        display : flex;
        align-items : center;
        background-color : white;
        padding : 5px;
        flex-grow : 1;
        padding-left : 15px;
        padding-right : 15px;
        box-sizing : border-box;
        margin-right : 10px;
        border-radius : 5px;
        justify-content : space-between;

        p {
            font-size : 20px;
        }

        .saving {
            display : flex;
            align-items : center;
            border : 1px solid lightgrey;
            border-radius : 5px;
            padding : 5px;
            margin-left : 10px;
            min-width : 80px;
            justify-content : space-between;
            p {
                font-size : 14px;
            }

        }
    }

`

export default SideBarMailEditor