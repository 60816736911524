import React from 'react';
import styled from 'styled-components';

const DefaultComponent = () => {

    const rendering = () => {
        return (
            <DefaultComponentSC>
                
            </DefaultComponentSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const DefaultComponentSC = styled.div`
    
`

export default DefaultComponent