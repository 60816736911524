import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { IndividualSessionContext } from '../../IndividualSession/IndividualSession';

const NoInstanceSelected = () => {

    const { setAddInstanceToggled } = React.useContext(IndividualSessionContext);

    const rendering = () => {
        return (
            <NoInstanceSelectedSC>
                <Content>
                    <p>Select a training on top or schedule a new one</p>
                    <Button 
                        style={{ width : 'auto', marginTop : '20px'}} 
                        variant="outlined" 
                        color="primary"
                        onClick={() => setAddInstanceToggled(true)}    
                    >Schedule a new training</Button>
                </Content>
            </NoInstanceSelectedSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const NoInstanceSelectedSC = styled.div`
    background-color: white;
    flex-grow: 1;
    margin: 20px;
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;



export default NoInstanceSelected;