import React from 'react';
import styled from 'styled-components';
import moment from 'moment'; 
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MailPreview from '../../../mails/mailPreview/MailPreview';
import Modal from '../../../GeneralComponents/Modal';
import MailViewer from './MailViewer';

const MailsOverview = ({ mails, individualSession, attendee}) => {

    const [mailPreview, setMailPreview] = React.useState(null);

    const mailsGeneralDate = mails.map(mail => {
        return ({
            ...mail, 
            date : mail.type === 'scheduled' ? mail.jobProperties.sendAt : mail.createdAt
        })
    }); 
    const sortedMails = mailsGeneralDate.sort((a, b) => new Date(a.date) - new Date(b.date));

    const rendering = () => {
        return (
            <MailsOverviewSC>
                {sortedMails.map(mail => {

                    const timing = mail.isScheduled ? mail.jobProperties.sendAt : mail.createdAt; 
                    const status = mail.isScheduled ? 'Scheduled' : 'Sent';

                    return (
                        <>

                            {/* Individual mail */}
                            <>	
                                {
                                    mailPreview && <MailViewer
                                        mail={mailPreview}
                                        closeFunction={() => setMailPreview(null)}
                                        individualSession={individualSession}
                                        attendee={attendee}
                                    />                                       
                                }
                            </>


                            <div className='mail-container' key={mail._id} onClick={() => setMailPreview(mail)}>
                                <div className='icon'>
                                    <MailOutlineIcon sx={{ stroke : 'white', strokeWidth : 1}}/>
                                </div>
                                <div className='title-and-date'>
                                    <h1>{mail.subject}</h1>
                                    <h3>{moment(mail.date).format('DD/MM/YYYY HH:mm')}</h3>
                                </div>
                                <div className='status'>
                                    <p className={`${status === 'Scheduled' ? 'status-grey' : 'status-green'}`}>{status}</p>
                                </div>
                            </div>
                        </>
                        )
                    })
                }

            </MailsOverviewSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const MailsOverviewSC = styled.div`
    padding : 20px;
    display : flex; 
    flex-direction : column;
    flex : 1;
    overflow-y : auto;
    
    .mail-container {
        display : flex;
        align-items : center;
        border : 1px solid #e0e0e0;
        border-radius : 10px;
        margin-bottom : 10px;
        padding : 15px;

        &:hover {
            background-color : #f0f0f0;
            cursor: pointer;
        }
    
        .icon {
            margin-right : 20px;
            &>svg {
                font-size : 30px;
            }
        }

        .title-and-date {
            flex : 1;
            display : flex;
            flex-direction : column;

            h4 {
                margin : 0;
            }
            p {
                margin : 0;
            }
        }
    
    }

    .status {

        p {
            font-size : 12px;
            border-radius : 5px;
            padding : 5px;
        }

    }
`

export default MailsOverview