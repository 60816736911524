import React from 'react';
import styled from 'styled-components';

const FreeText = ({ item }) => {

    const rendering = () => {
        return (
            <FreeTextSC>
                <p className='question'>{item.question}</p>
                <input 
                    type="text" 
                    id={item.question} 
                    name={item.question}
                    autoComplete='off'    
                ></input>
            </FreeTextSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const FreeTextSC = styled.div`
    display : flex;
    flex-direction : column;
    padding-bottom : 1px;

    .question {
        font-size : 1.2rem;
        margin-bottom : 10px;
    }

    input {
        padding : 15px;
        font-size : 1.1rem; 
        font-family : inherit;
        flex-grow : 1;
        outline : none;
        border : 1px solid grey;
        border-radius : 3px;
    }
`

export default FreeText