import React from 'react';
import styled from 'styled-components';
import Modal from '../../GeneralComponents/Modal';
import { Button } from '@mui/material';
import { IndividualSessionContext } from '../IndividualSession';
import { IndividualTrainingContext } from '../../IndividualTrainingFinal/IndividiualTraining';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import moment from 'moment'; 
import { useNavigate } from 'react-router-dom';

const SessionList = () => {

    const { toggleSessionList } = React.useContext(IndividualSessionContext);
    const { sessionsForTraining, setIndividualSession } = React.useContext(IndividualTrainingContext);
    const navigate = useNavigate();

    const AvatarNameComponent = (params) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={params.data.trainer.imageUrl} alt="avatar" style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 10 }} />
                <span>{params.data.trainer.firstName}</span>
            </div>
        );
    };

    const LocationComponent = (params) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                    <>
                        <img src={params.data.location.thumbnailImageUrl} alt="avatar" style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 10 }} />
                        <span>{params.data.locationType === 'our-location' ? params.data.location.title : params.data.location.place}</span>
                    </>
            </div>
        );
    };

    const AttendeesComponent = (params) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <p>{`${params.data.attendees.length} / ${params.data.capacity}`}</p>
            </div>
    )};

    const colDefs=[
        { field: 'startDate', headerName : 'Start date', valueFormatter : p => moment(new Date(p.value)).format('DD/MM/YYYY'), flex : 2},
        { valueGetter : p => p.data, cellRenderer : AvatarNameComponent, headerName : 'Trainer', flex : 2 },
        { valueGetter : p => p.data, cellRenderer : LocationComponent, headerName : 'Location', flex : 2 },
        { valueGetter : p => p.data, cellRenderer : AttendeesComponent, headerName : 'Attendees', flex : 2 },
    ];

    const selectInstance = (instance) => {
        navigate(`/trainings/${instance.trainingId}/sessions/${instance._id}`);
        toggleSessionList(false);
    }

    const rendering = () => {

        return (
            <Modal>
                <SessionListSC>
                    <div className="header">
                        <h1>Session List</h1>
                        <Button variant="contained" color="primary" onClick={() => toggleSessionList(false)}>
                            Close
                        </Button>
                    </div>
                    <div className='ag-container'>
                        <div className='ag-theme-material' style={{ height: 400, width: 600 }}>
                            <AgGridReact
                                style={{ width: '100%', height: '100%' }}
                                rowData={sessionsForTraining} 
                                columnDefs={colDefs}
                                onRowClicked={p => selectInstance(p.data)}
                            />
                        </div>
                    </div>
                </SessionListSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SessionListSC = styled.div`
    min-width : 80vw;
    min-height : 80vh;
    background-color : white;
    display : flex; 
    flex-direction : column;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
    }

    .ag-container {
        background-color : red;
        flex : 1;
        display : flex; 
        flex-direction : column; 

        .ag-theme-material {
            min-width : 100%;
            flex-grow: 1;

            --ag-font-size: inherit;
            --ag-font-family: inherit;    
            --ag-material-accent-color : #1976d2;
            --ag-selected-row-background-color : rgba(42,115,207,0.1);

            .ag-cell-focus {
                outline: none; /* Remove the default outline */
            }
        }   
    }

`;

export default SessionList