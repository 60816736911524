import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import UserActions from './UserActions';

const UserInfo = ({ attendee }) => {

    const rendering = () => {
        return (
            <UserInfoSC>
                <div className='personal-info'>
                    <p>{attendee.firstName} {attendee.lastName}</p>
                    <p>{attendee.email}</p>
                    <br/>
                    <p>{attendee.company}</p>
                    <p>{attendee.role}</p>

                </div>
                <div className='sign-up-info' style={{ marginTop : '20px'}}>
                    <p>signed up : {moment(attendee.createdAt).format('DD/MM/YYYY hh:mm')}</p>
                    <p>email accounting : {attendee.emailAccounting ? attendee.emailAccounting : attendee.email}</p>
                    <p>VAT : {attendee.vatNumber ? attendee.vatNumber : 'none'}</p>
                    <p>Discount code : {attendee.discountCode ? attendee.discountCode : 'none'}</p>
                </div>

                <UserActions
                    attendee={attendee}
                />

            </UserInfoSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const UserInfoSC = styled.div`
    padding : 20px;
    border-right : 1px solid #e0e0e0;
    min-width : 300px;

`

export default UserInfo