import React from 'react';
import styled from 'styled-components';
import SurveyResponsesOverview from './SurveyResonsesOverview';
import CertificatesShared from './CertificatesShared';
import UICard from '../../../GeneralComponents/UIContainer/UICard';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';

const SurveyAndCertificate = ({ individualSession }) => {

    const rendering = () => {
        return (
            <UICard styleTags={{ marginTop : '20px', marginLeft : '20px'}}>

                <div className='title-component'>
                    <MilitaryTechOutlinedIcon sx={{ stroke : 'white', strokeWidth : 1}}/>
                    <h1 className='card-title'>Actions</h1>
                </div>

                <SurveyResponsesOverview individualSession={individualSession}/>
                {/* <CertificatesShared individualSession={individualSession}/> */}
            </UICard>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SurveyAndCertificateSC = styled.div`

    .sub-title-certs-and-surveys {
        padding : 10px;
        font-weight : 300;
        color : #595959;
        font-size : 14px;
    }


`

export default SurveyAndCertificate