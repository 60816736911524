import React from 'react';
import styled from 'styled-components';
import { colors } from '../../variables/Colors';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import EmailIcon from '@mui/icons-material/Email';
import { HeigtsPaddingBorderRadius } from '../../variables/HeigtsPaddingBorderRadius';
import { useNavigate } from 'react-router-dom';
import GroupIcon from '@mui/icons-material/Group';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import SchoolIcon from '@mui/icons-material/School';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import SignalCellularAltOutlinedIcon from '@mui/icons-material/SignalCellularAltOutlined';
import GridOnOutlinedIcon from '@mui/icons-material/GridOnOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
const { headerHeight } = HeigtsPaddingBorderRadius;

const Sidebar = ({ appState , setAppState }) => {

    const [activeIndex, setActiveIndex] = React.useState(0);

    const navigate = useNavigate();
    const currentlyActive = window.location.hash.split('/')[1];

    const items = [{
        name : 'Dashboard',
        icon : <SignalCellularAltOutlinedIcon sx={{ stroke : '#f5f5f5', strokeWidth : 1}}/>,
        path : '/'
    }
    ,{
        name : 'Trainings',
        icon : <GridOnOutlinedIcon sx={{ stroke : '#f5f5f5', strokeWidth : 1}}/>,
        path : '/trainings'
    },{
        name : 'Trainers',
        icon : <PersonOutlineOutlinedIcon sx={{ stroke : '#f5f5f5', strokeWidth : 1}}/>,
        path : '/trainers'
    
    },{
        name : 'Locations',
        icon : <CorporateFareOutlinedIcon sx={{ stroke : '#f5f5f5', strokeWidth : 1}}/>,
        path : '/locations'
    },{
        name : 'Mail editor',
        icon : <EmailOutlinedIcon sx={{ stroke : '#f5f5f5', strokeWidth : 1}}/>,
        path : '/mail-editor'
    },{
        name : 'Certificates',
        icon : <MilitaryTechOutlinedIcon sx={{ stroke : '#f5f5f5', strokeWidth : 1}}/>,
        path : '/certificates/overview',
    },{
        name : 'Survey',
        icon : <ListAltIcon sx={{ stroke : '#f5f5f5', strokeWidth : 1}}/>,
        path : '/survey-responses',
    },{
        name : 'Users',
        icon : <PeopleAltOutlinedIcon sx={{ stroke : '#f5f5f5', strokeWidth : 1}}/>,
        path : '/user-management',
    }]

    const rendering = () => {

        const clickItem = (index, item) => {
            setActiveIndex(index);
            navigate(item.path)
        }

        return (
            <SidebarSC style={{ 
                    minWidth : appState.sideBarExpanded ? '220px':'60px', 
                    maxWidth : appState.sideBarExpanded ? '220px':'50px',
                    padding : appState.sideBarExpanded ? '15px':'10px',
                    
                    }}>

                <div className='title-item' onClick={() => setAppState({...appState, sideBarExpanded : !appState.sideBarExpanded})}
>
                    {appState.sideBarExpanded ? 

                        <div className='expanded-menu-option'>
                            <KeyboardDoubleArrowLeftIcon 
                            />
                        </div>
                        :
                        <div style={{ padding : '10px'}}>
                        <ArrowRightIcon />
                        </div>

                    }
                </div>

                {items.map((item, index) => {

                    const currentIndex = items.findIndex((item) => item.path.split('/')[1] === currentlyActive);
                    const selected = currentIndex === index ? 'selected' : '';

                    return (
                        <div 
                            key={index} 
                            className={`menu-item ${selected}`}
                            onClick={() => clickItem(index, item)}    
                        >
                                {item.icon}
                                {appState.sideBarExpanded && <h1>{item.name}</h1>}
                        </div>
                    )
                }
                )}
            </SidebarSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SidebarSC = styled.div`
    margin-top : 10px;
    border-radius : 5px;
    margin-bottom : 10px;
    min-width : 220px;
    max-width : 220px;
    display : flex; 
    flex-direction : column;
    padding : 15px;
    box-sizing : border-box;
    transition : 0.2s;
    background-color : white;

    .menu-item {
        display : flex;
        flex-direction : row;
        padding : 10px;
        margin-bottom : 8px;

        &>svg {
            max-width : 20px;
            max-height : 20px;
            margin-right : 10px;
        }

        &:hover {
            cursor : pointer;
        }
    }

    .expanded-menu-option {
        display : flex;
        flex-direction : row;
        padding : 10px;
        margin-bottom : 8px;
        justify-content : space-between;

        &>svg {
            max-width : 20px;
            max-height : 20px;
            margin-right : 10px;
        }
    }

    .selected {
        background-color : #f5f5f5;
        border-radius : 5px;

        svg {
            color : ${colors.primaryBlue}
        }
    }


    /* .title-item {
        display : flex;
        justify-content : space-between;
        align-items : center;
        box-sizing : border-box;
        padding : 20px;
    } */

    /* .menu-title {
        font-weight : 500;
        font-size : 18px;
    }

    .menu-item {

        &:hover {
            cursor : pointer; 
        }

        .main {
            display : flex;
            align-items : center;
            box-sizing : border-box;

            >p {
                font-size : 17px;
            }

            &>svg {
                min-width : 50px;
                max-width : 50px;
                min-height : 50px;;
                max-height : 50px;;
                padding : 17px;
                box-sizing : border-box;
                color : grey;
                padding-right : 0;
                padding-left : 0;
                
                &:hover {
                    cursor : pointer;
                }
            }
        }

        .selected { 

            &>svg {
                color : ${colors.darkGrey};
            }
        }

        .sub-menu {
            display : flex;
            flex-direction : column;
            margin-top : -10px;
            padding-left : 40px;

            
            &>p {
                box-sizing : border-box;
                font-size : 14px;
                color : ${colors.midGrey};
                transition : 0.2s;
                padding-top : 5px;
                padding-bottom : 5px;
                padding-left:  10px;

                &:hover {
                    cursor : pointer;
                }

            }
        }


    }

    .selected {
        font-weight : 600;
    }

    &>div {
        box-sizing : border-box;


        &>p {
            font-weight : 0;
            font-size : 14px;
        }
    }  */

`

export default Sidebar