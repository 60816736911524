import React from 'react';
import styled from 'styled-components';
import { colors } from '../../variables/Colors';
import { useNavigate } from 'react-router-dom';
import UICard from '../GeneralComponents/UIContainer/UICard';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
const TrainingTile = ({ training, setState, state, instancesCount}) => {

    const navigate = useNavigate();

    const rendering = () => {
        return (
            <UICard style={{ padding : '5px'}}>
                <TrainingTileSC 
                    onClick={() => navigate(`/trainings/${training._id}/sessions/undefined`)}   
                >
                    <div className='background-image' style={{backgroundImage : `url(${training.imageUrl})`}}></div>
                    <h1 className='training-title'>{training.name}</h1>
                    <div className='number-of-instances'>
                    {training.customProperties.onDemand && <p className='on-demand-tag'>ON DEMAND</p>}

                        <div style={{ display : 'flex', alignItems : 'center'}}>
                            <CalendarMonthIcon sx={{ stroke : 'white', strokeWidth : 1, fontSize : '16px', color : 'darkgrey'}}/>
                            {`${instancesCount} Session${instancesCount > 1 ? 's' : ''}`}
                        </div>


                    </div>
                </TrainingTileSC>
            </UICard>                            

        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingTileSC = styled.div`
    position : relative;

    &:hover {
        cursor : pointer;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.5); /* White color with 50% opacity */
        }    
    }

    .background-image { 
        aspect-ratio: 16 / 10;
        background-size : cover;
        background-position : center;
        margin: 10px;
    }

    .training-title {
        font-size : 20px;
        margin-top : 10px;
        padding : 10px;
        padding-bottom : 0;
        display: -webkit-box;        /* Use a flexible box layout */
        -webkit-box-orient: vertical; /* Make the box behave vertically */
        -webkit-line-clamp: 2;        /* Limit to 2 lines */
        overflow: hidden;            /* Hide overflowed text */
        text-overflow: ellipsis;     /* Add ellipsis at the end */
    }

    .number-of-instances {
        display : flex; 
        flex-direction : column;
        justify-content : flex-start;
        
        font-size : 16px;
        font-weight : 300;
        padding : 10px;
        padding-top : 5px;
        color : ${colors.lightGrey};
        display : flex; 


        &>svg {
            margin-right : 5px;
        }
    }

    .on-demand-tag {
        background-color : lightgrey;
        color : white;
        padding : 5px;
        border-radius : 5px;
        margin-bottom : 5px;
        font-size : 12px;
        min-width : 0;
        width: fit-content;
    }






`

export default TrainingTile