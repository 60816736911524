import React from 'react';
import styled from 'styled-components';
import RegisteredAttendee from './RegisteredAttendee';
import UICard from '../../../GeneralComponents/UIContainer/UICard';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
import { AgGridReact } from 'ag-grid-react';
import { IndividualSessionContext } from '../../IndividualSession';

const RegisteredUsers = ({ individualSession }) => {

    const { setIndividualAttendee } = React.useContext(IndividualSessionContext);
    const openSlots = individualSession.capacity - individualSession.attendees.length;

    const StatusComponent = (p) => {    
        const { paymentStatus } = p.data;
        return ( p.data.placeholder ? <p></p> :
            <div style={{ margin : 0, padding : 0, lineHeight : 'normal', display : 'inline-block', verticalAlign : 'middle'}}>
                <p className='status-green' style={{ padding : '5px 15px 5px 15px', fontSize : '12px', borderRadius : '5px'}}>{paymentStatus}</p>
            </div>
        );
    };

    const AvatarComponent = (p) => {
        return ( p.data.placeholder ? <p></p> :
            <div style={{ display : 'flex', alignItems : 'center', height : '100%', marginLeft : '-10px'}}>
                <img src='https://thumbs.dreamstime.com/b/man-portrait-beard-modern-avatar-flat-design-vector-illustrat-male-illustration-91447819.jpg' alt='avatar' style={{ borderRadius : '50%', width : '30px', height : '30px', marginLeft : '10px'}}/>
                <p style={{ marginLeft : '10px'}}>{`${p.data.firstName} ${p.data.lastName}`}</p>
            </div>
        );
    };


    const attendeesAndPlaceholders = individualSession.attendees.concat(Array.from({ length: openSlots }).map(() => ({ 
        placeholder : true,
        firstName : '...', 
        lastName : '', 
        company : '', 
        createdAt : '', 
        paymentStatus : ''
    })));

    const rendering = () => {
        return (
            <UICard styleTags={{ margin : "20px", marginTop : 0, marginBottom : 0, flex : 1, marginLeft : 0}}>
                <RegisteredUsersSC>
                    <div className='title-component' style={{ padding : 0, paddingBottom : "20px"}}>
                        <MilitaryTechOutlinedIcon sx={{ stroke : 'white', strokeWidth : 1}}/>
                        <h1 className='card-title'>Registered users</h1>
                    </div>     
                    <div className='table-container-wrapper'>           
                        <div className='ag-theme-material' style={{height : "800px", minWidth : '100%'}} >
                            <AgGridReact
                                rowData={attendeesAndPlaceholders} 
                                columnDefs={[
                                    {
                                        headerName: 'Name',
                                        valueGetter: p => p.data,
                                        cellRenderer : AvatarComponent,
                                        flex: 2
                                    },
                                    {field : 'company', headerName : 'Company', flex : 1},
                                    {
                                        headerName: 'Registered',
                                        valueGetter: p => {
                                            const date = new Date(p.data.createdAt);
                                            if(isNaN(date.getTime())) return '';
                                            return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
                                        },
                                        flex: 2
                                    },
                                    { valueGetter : p => p.data, cellRenderer : StatusComponent, headerName : 'Status', flex : 1 },

                                ]}
                                getRowStyle={(params) => {
                                    if(params.data.placeholder) return { color : '#d4d4d4', fontWeight : '200'};
                                    return {};
                                }}
                                onRowClicked={(e) => !e.data.placeholder && setIndividualAttendee(e.data)}
                            />
                        </div>
                    </div>


                    {/* <div className='registered-users-container'>
                        {individualSession.attendees.map((attendee, index) => {
                            return (
                                <RegisteredAttendee 
                                    attendee={attendee} 
                                    key={index}
                                />
                            );
                        })}

                        {Array.from({ length: openSlots }).map((_, index) => (
                            <div className='open-slot' key={index}>
                                <img src='https://cdn3.iconfinder.com/data/icons/account-1/64/Account-06-512.png' alt='avatar' style={{ borderRadius : '50%', width : '30px', height : '30px', marginLeft : '10px'}}/>
                                <p>Open slot</p>
                            </div>
                        ))}
                    </div> */}
                </RegisteredUsersSC>
            </UICard>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const RegisteredUsersSC = styled.div`
    background-color : white;
    border-radius : 10px;
    flex-grow : 1;
    padding : 15px;
    display : flex;
    flex : 1;
    flex-direction : column;

    .title {
        padding-bottom : 15px;
    }

    .registered-users-container {
        display : flex;
        flex-direction : column;
        border-radius : 10px;
    }

    .open-slot {
        display : flex;
        align-items : center;
        height : 50px;
        border-radius : 5px;
        background-color : #fafafa;
        margin-top : 10px;
        color : grey;
        font-weight : 300;

        img {
            border-radius : 50%;
            width : 30px;
            height : 30px;
            margin-right : 10px;
        }
    }

    .table-container-wrapper {
        flex-grow : 1;
        min-height : 800px;
        background-color : green;
    }

    .ag-theme-material {
        --ag-font-size: inherit;
        --ag-font-family: inherit;    
        --ag-material-accent-color : #1976d2;
        --ag-selected-row-background-color : rgba(42,115,207,0.1);
        .ag-cell-focus {
            outline: none; /* Remove the default outline */
        }
    }

`

export default RegisteredUsers