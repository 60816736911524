import React from 'react';
import styled from 'styled-components';
import SelectLocationBar from './SelectLocationBar';
import TriggerBasedEmails from './TriggerBasedEmails';
import { defaultTouchPointsArray } from '../addTraining/defaultTouchPointsArray';
import ScheduledEmails from './ScheduleEmails';
import IndividualTouchPoint from './IndividualTouchPoint/IndividualTouchPoint';

export const MailScheduleBothLocationsContext = React.createContext();

const MailScheduleBothLocations = ({ 
    training,
    trainingToEditLocal,
    setTrainingToEditLocal,
    mailScheduleRef
}) => {

    const [locationType, setLocationType] = React.useState('our-location');
    const [touchPointsLocal, setTouchPointsLocal] = React.useState(null);
    const [individualTouchPoint, setIndividualTouchPoint] = React.useState(null);

    // When rendering the comonent, set both our location and customer locations 
    // touchpoints to the one from the training
    React.useLayoutEffect(() => {
        if(training) {
            setTouchPointsLocal({
                ourLocation : training.defaultTouchPointsArray,
                customerLocation : training.customerLocationTouchPointsArray
            })
        }
    }, [])

    // When adding a new touch point, set the touch points array from the parent training
    // training to edit local
    React.useEffect(() => {
        touchPointsLocal && 
        
        setTrainingToEditLocal({
            ...trainingToEditLocal,
            defaultTouchPointsArray : touchPointsLocal.ourLocation,
            customerLocationTouchPointsArray : touchPointsLocal.customerLocation
        })
    },[touchPointsLocal]);

    const rendering = () => {
        if(touchPointsLocal) {

        const touchPoints= locationType === 'our-location' ? touchPointsLocal.ourLocation : touchPointsLocal.customerLocation;
        const triggeredEmails = touchPoints.filter(touchPoint => touchPoint.timingMode === 'Trigger');
        const scheduledEmails = touchPoints.filter(touchPoint => touchPoint.timingMode === 'Scheduled');

        console.log('scheduled emails : ', scheduledEmails);

        return (
            <MailScheduleBothLocationsContext.Provider value={{ 
                locationType, 
                setLocationType,
                setIndividualTouchPoint,
                touchPointsLocal,
                setTouchPointsLocal,
            }}>
                <MailScheduleBothLocationsSC ref={mailScheduleRef} id='section4' className='content-section'>

                    <>
                        {individualTouchPoint && 
                            <IndividualTouchPoint 
                                individualTouchPoint={individualTouchPoint} 
                                setIndividualTouchPoint={setIndividualTouchPoint}
                                touchPointsLocal={touchPointsLocal}
                                setTouchPointsLocal={setTouchPointsLocal}
                            />
                        }
                    </>

                    <h1>Mail schedule</h1>
                    <div className='schedule-container'>
                        <SelectLocationBar />
                        <TriggerBasedEmails touchPoints={triggeredEmails} setTouchPointsLocal={setTouchPointsLocal}/>
                        <div className='divider' style={{ minHeight : '15px', backgroundColor : 'white'}}></div>
                        <ScheduledEmails touchPoints={scheduledEmails} setTouchPointsLocal={setTouchPointsLocal}/>
                    </div>
                </MailScheduleBothLocationsSC>
            </MailScheduleBothLocationsContext.Provider>
        );
        }
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const MailScheduleBothLocationsSC = styled.div`
    background-color: white;
    padding : 20px;
    margin : 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    margin-left : 0;

    h1 {
        font-size : 1.3rem;
        font-weight : 400;
        font-family : inherit;
        padding-bottom : 10px;
    }

    .schedule-container {
        background-color : #F6F8FA;
        background-image: radial-gradient(#e8e8e8 1px, transparent 1px);
        background-size: 30px 30px;
        padding-bottom : 20px;
        overflow : hidden;
    }
`

export default MailScheduleBothLocations