import { Gauge } from '@mui/x-charts';
import React from 'react';
import styled from 'styled-components';

const CertificatesShared = () => {

    const rendering = () => {
        return (
            <CertificatesSharedSC>
            <Gauge width={100} height={100} value={16} />
            <div style={{display : 'flex', flexDirection : 'column', padding : '10px'}}>
                <p className='title-certs-and-surveys'>Feedback responses</p>
                <p className='sub-title-certs-and-surveys'>{16}% Surveys filled in</p>
            </div>
            </CertificatesSharedSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const CertificatesSharedSC = styled.div`
    display : flex;
    align-items : center;
    border-radius : 5px;
    margin : 20px;
    margin-top : 0;
    `

export default CertificatesShared