import React from 'react';
import styled from 'styled-components';
import AddInstanceNavigation from './addInstanceNavigation/AddInstanceNavigation'; 
import AddInstanceFooter from './addInstanceFooter/AddInstanceFooter';
import AddInstanceDates from './addInstanceDates/AddInstanceDates';
import ReviewAndSubmit from './reviewAndSubmit/ReviewAndSubmit';
import AddInstanceProperties from './addInstanceProperties/AddInstanceProperties';
import Modal from '../GeneralComponents/Modal';
import { colors } from '../../variables/Colors';
import Axios from 'axios'; 
import { backEndAdress } from '../../variables/back-endAdress';
import { useNavigate } from 'react-router-dom';
import { set, useForm } from 'react-hook-form';
import ActionInProgress from '../GeneralComponents/ActionInProgress';

export const AddInstanceContext = React.createContext();

const AddInstance = ({ training, closeFunction, setReRender }) => {

/* =================================================================
    Package imports
================================================================= */
    const formRef = React.useRef(null);
    const navigate = useNavigate();
    const form = useForm();
    const { register, handleSubmit, watch, trigger, control, formState } = form;
    const { errors, isValidating } = formState;

/* =================================================================
    States
================================================================= */
    const [currentMenu, setCurrentMenu] = React.useState(1);
    const [dates, setDates] = React.useState([]);
    const [trainer, setTrainer] = React.useState(null);
    const [location, setLocation] = React.useState(null);
    const [price, setPrice] = React.useState(null);
    const [language, setLanguage] = React.useState(null);
    const [locationType, setLocationType] = React.useState('our-location');

    const [adress, setAdress] = React.useState('');
    const [place, setPlace] = React.useState('');
    
    const [addingInstance, setAddingInstance] = React.useState(false);
    const [instanceAdded, setInstanceAdded] = React.useState(false);
    const [addInstanceError, setAddInstanceError] = React.useState(false);

/* =================================================================
    Functions
================================================================= */
    const submitIt = (data) => {

        setAddingInstance(true);

        const { capacity, adress, place } = data;

        const startDate = new Date(dates[0]).toISOString();
        const trainingId = training._id; 
        const trainingName = training.name;
        const touchPoints =  training.defaultTouchPointsArray;

        const instanceName = 'testing';
        
        const handleBars = {
            userName : '[userName]',
            trainingName,
            trainingDescription : training.description,
            adress : location ? location.adress : '',
            locationInstructions : location ? location.instructions : '[locationInstructions]',
            instanceLanguage : language,
            trainerName : trainer ? `${trainer.firstName} ${trainer.lastName}` : '[trainerName]',
            trainerDescription : trainer ? trainer.shortDescription : '[trainerDescription]',
            trainerImageURL : 'trainerImageURL',
        }

        const instance = {
            trainingId,
            trainingName, 
            instanceName : 'instance name',
            trainer,
            price,
            location : locationType === 'our-location' ? location : { adress, place, thumbnailImageId : 'office.png'},
            locationType,
            language,
            capacity,
            startDate,
            trainingDates : [dates],
            attendees : [],
            dates,
            handleBars,
            touchPoints
        };

        const submitFunction = async () => {
            try {
                const response = await Axios.post(`${backEndAdress}/api/instances`, instance);
                setInstanceAdded(response.data);
                setReRender(Math.random());
                
            } catch (error) {
                console.log('error add instance', error);
                setAddInstanceError(error);
            }
        }        
        
        submitFunction();
       
    }

    const goToSession = (instanceAdded) => {
        closeFunction();
        navigate(`/trainings/${training._id}/sessions/${instanceAdded._id}`);
    }

    const createAnotherOne = () => {
        setDates([]);
        setTrainer(null);
        setLocation(null);
        setPrice(null);
        setLanguage(null);
        setCurrentMenu(1);
        setAddingInstance(false);
        setInstanceAdded(false);
        setAddInstanceError(false);
    }

/* =================================================================
    Rendering
================================================================= */

    const rendering = () => {
        return (
            <AddInstanceContext.Provider value={{
                currentMenu, 
                setCurrentMenu,
                dates,
                setDates,
                trainer,
                setTrainer,
                location,
                setLocation,
                price, 
                setPrice,
                language,
                setLanguage,
                saveFunction : submitIt,
                register, 
                errors, 
                form,
                formRef,
                locationType,
                setLocationType
            }}>
                <Modal>

                    <> {/* Action in progress modal  */}
                        {addingInstance &&
                            <ActionInProgress
                                text='Scheduling training' 
                                successText='Succesfully scheduled training' 
                                errorText='Something went wrong' 
                                completed={instanceAdded}
                                error={addInstanceError}
                                successOption1={{
                                    text : 'Go to session',
                                    onClick : () => {
                                        goToSession(instanceAdded);
                                    }
                                }}
                                successOption2={{
                                    text : 'Create another one',
                                    onClick : () => {
                                        createAnotherOne();
                                    }
                                }}
                                errorOption1={{
                                    text : 'Cancel',
                                    onClick : () => {
                                        console.log('trying again')
                                    }
                                }}
                            />
                        }
                    </>


                    <AddInstanceSC>
                        <AddInstanceNavigation/>
                        <form noValidate onSubmit={handleSubmit(submitIt)} ref={formRef}>
                            <div className='content'>
                                {currentMenu === 1 && <AddInstanceDates />}
                                {currentMenu === 2 &&  <AddInstanceProperties />}
                            </div>
                        </form>
                        <AddInstanceFooter closeFunction={closeFunction} />
                    </AddInstanceSC>
                </Modal>
            </AddInstanceContext.Provider>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const AddInstanceSC = styled.div`
    min-width : 350px;
    width : 80vw;
    min-height : 500px;
    height : 80vh;
    background-color : white;
    border-radius : 5px;
    display : flex;
    flex-direction : column;
    box-sizing : border-box;
    position : relative; 
    overflow : hidden;
    box-shadow : 0px 0px 5px 0px ${colors.lightGrey};

    form {
        width : 100%;
        flex-grow : 1;
        display : flex;
        flex-direction : column;
    }

    .content {
        flex-grow : 1;
        display : flex; 
        overflow : hidden;
    }
`

export default AddInstance