import React from 'react';
import styled from 'styled-components';
import Axios from 'axios' ;
import { backEndAdress } from '../../../../variables/back-endAdress';
import SendIcon from '@mui/icons-material/Send';
import { IndividualSessionContext } from '../../IndividualSession';
import UICard from '../../../GeneralComponents/UIContainer/UICard';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';

const MailJobs = ({ individualSession }) => {

    const [mails, setMails] = React.useState([]);
    const { setJobPreview } = React.useContext(IndividualSessionContext);

    React.useEffect(() => {
        const fetchMailJobs = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/mails/for-instance/${individualSession._id}`)
                console.log('responzio : ', response.data);
                setMails(response.data);
            } catch (error) {
                console.log(error);
            }
        }

        fetchMailJobs();
    }, []);

    const rendering = () => {
        return (
            <UICard styleTags={{ marginLeft : "20px", marginTop : "20px"}}>
                <MailJobsSC>
                    <div className='title-component' style={{ paddingTop : 0}}>
                        <MilitaryTechOutlinedIcon sx={{ stroke : 'white', strokeWidth : 1}}/>
                        <h1 className='card-title'>Scheduled e-mails</h1>
                    </div>
                    {mails.length === 0 && 
                        <div className='no-mails-div'>
                            <p>there are currently no mails sent / scheduled</p>
                        </div>
                    }
                    <div className='mail-items'>
                        {mails.length > 0 && mails.map((mail) => {
                            return (
                                <div className='mail-item'>
                                    <SendIcon />
                                    <div className='subject-and-receiver'>
                                        <h1>{mail.subject}</h1>
                                        <p style={{  color : 'grey'}}>{mail.to}</p>
                                    </div>
                                    <div className={`status ${mail.isScheduled ? 'status-amber' : 'status-green'}`}>
                                        <p style={{ 
                                            fontSize : '12px',
                                            fontWeight : 300
                                        }}>{mail.isScheduled ? 'scheduled' : 'sent'}</p>
                                    </div>
                                </div>
                            )
                        }
                        )}
                    </div>
                        {/* {mailJobs.length > 0 && mailJobs.map((mailJob) => {
                        return (
                            <div className='mail-job' onClick={() => setJobPreview(mailJob)}>
                                <div style={{display : 'flex', alignItems : 'center', flex : 1}}>
                                    <SendIcon />
                                    
                                    <div className='mail-job-info'>
                                        <h1 className='mail-job-title'>{mailJob.subject}</h1>
                                        <h3 className='sendto'>{mailJob.sendTo}sd kflmsd fjlmds jfdsmk j</h3>
                                    </div>
                                </div>
                                <div className='status'>
                                    <p>sent</p>
                                </div>

                            </div>
                        )
                    })} */}
                </MailJobsSC>
            </UICard>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const MailJobsSC = styled.div`
    background-color : white;
    margin-top : 20px;
    min-height : 400px;
    max-width : 100%;
    overflow : hidden;
    display : flex; 
    flex-direction : column;


    .no-mails-div {
        display : flex;
        flex-direction : column;
        align-items : center;
        justify-content : center;
        margin-top : 50px;

        img {
            width : 150px;
            height : 150px;
        }

        p {
            font-size : 14px;
            color : #595959;
            margin-top : 10px;
        }
    }

    .mail-items {
        display : flex; 
        flex-direction : column;

        .mail-item {
            display : flex; 
            min-height : 60px;
            max-height : 60px;
            align-items : center;
            border-bottom : 1px solid #e6e6e6;

            &>svg {
                min-width : 40px;
                max-width  : 40px;
                padding : 5px;
                box-sizing : border-box;
            }

            .subject-and-receiver {
                display : flex; 
                flex-direction : column;
                box-sizing : border-box;
                flex : 1;
                padding : 5px;

                p {
                    font-size : 14px;
                    margin : 0;
                }
            }

            .status {
                min-width : 70px;
                max-width : 70px;
                display : flex;
                align-items : center;
                font-size : 14px;
                justify-content : center;
                padding : 5px;
                box-sizing : border-box;
                border-radius : 5px;
                margin-right : 10px;
            }
        }
    }

`

export default MailJobs