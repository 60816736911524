import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { IndividualSessionContext } from '../IndividualSession';

const UserActions = ({ attendee }) => {

    const { action, setAction, deleteUserFromInstance } = React.useContext(IndividualSessionContext);

    const rendering = () => {
        return (
            <UserActionsSC>
                <Button 
                    variant='outlined' 
                    color='primary'
                    onClick={() => setAction({
                        title : 'Are you sure you want to delete this user?',
                        textOption1 : 'Yes',
                        textOption2 : 'No',
                        function1 : () => deleteUserFromInstance(attendee._id),
                        function2 : () => setAction(null)
                    })}    
                >Delete user</Button>
            </UserActionsSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const UserActionsSC = styled.div`
    margin-top : 20px;   
`

export default UserActions