import React from 'react';
import styled from 'styled-components';
import Modal from '../../../GeneralComponents/Modal';
import { Button } from '@mui/material';
import Axios from 'axios'; 
import { backEndAdress } from '../../../../variables/back-endAdress';

const MailViewer = ({ mail, closeFunction, individualSession, attendee }) => {

    const [loadingHTML, setLoadingHTML] = React.useState(true);
    const [mailHTML, setMailHTML] = React.useState('');

    React.useEffect(() => {
        setLoadingHTML(true);

        const fetchMailHTML = async () => {
            try {
                const html = await Axios.post(`${backEndAdress}/api/mail-templates/get-html-file/${mail.mailTemplateId}` , { instance : individualSession , attendee : attendee});
                setMailHTML(html.data);
                setLoadingHTML(false);
            } catch (err) {
                console.log('Error fetching mail HTML: ', err);
                setLoadingHTML(false);
            }
        }

        fetchMailHTML();


    }, [mail]);

    const rendering = () => {
        
        return (
            <Modal>


                <MailViewerSC>
                    <Header>
                        <Button variant='outlined' onClick={closeFunction}>Close</Button>
                    </Header>
                    {loadingHTML && <p>Loading mail...</p>}

                    <div className='spacer'></div>
                    <div 
                        className='test' 
                        dangerouslySetInnerHTML={{__html : mailHTML}}>
                    </div>
                    <div className='spacer'></div>
                </MailViewerSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const MailViewerSC = styled.div`
    max-height: 90vh;
    min-height: 90vh;
    max-width: 90vw;
    min-width: 90vw;
    background-color: white;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;

    .spacer {
        min-height: 45px;
        background-color: #F7F8F9;
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    background-color: #f0f0f0;
`;

const CloseButton = styled(Button)`
    margin-left: auto;
`;

export default MailViewer;