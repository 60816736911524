import React, { useState } from 'react';
import styled from 'styled-components';
import { IndividualSessionContext } from '../../IndividualSession';
import { backEndAdress } from '../../../../variables/back-endAdress';
import Axios from 'axios';
import { Gauge } from '@mui/x-charts/Gauge';

const SurveyResponsesOverview = ({ individualSession }) => {


    const { setCertificatesPopUp } = React.useContext(IndividualSessionContext);
    const [surveyResponses, setSurveyResponses] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const { individualTraining } = React.useContext(IndividualSessionContext);

    React.useEffect(() => {
        const getSurveyResponses = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/survey-responses/by-instance/${individualSession._id}`);
                setSurveyResponses(response.data);
                setLoading(false); 
            } catch (error) {
                console.log('Error in getSurveyResponses', error);
                setLoading(false);
            }
        }

        individualSession.attendees.length === 0 ? setSurveyResponses([]) : getSurveyResponses();
    }, [individualSession]);

    const rendering = () => {

        const percentageFilledIn = (surveyResponses.length / individualSession.attendees.length) * 100;
        const roundedPercentageFilledIn = Math.round(percentageFilledIn);
        return (

            <SurveyResponsesOverviewSC onClick={() => setCertificatesPopUp(true)}>
                <Gauge width={100} height={100} value={roundedPercentageFilledIn || 0} />
                <div style={{display : 'flex', flexDirection : 'column', padding : '10px'}}>
                    <p className='title-certs-and-surveys'>Feedback responses</p>
                    <p className='sub-title-certs-and-surveys'>{roundedPercentageFilledIn || 0}% Surveys filled in</p>
                </div>
            </SurveyResponsesOverviewSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SurveyResponsesOverviewSC = styled.div`
    display : flex;
    align-items : center;
    border-radius : 5px;
    margin : 20px;
    margin-top : 0;

    &:hover {
        cursor : pointer;
        background-color : #f5f5f5;
    }

`


export default SurveyResponsesOverview