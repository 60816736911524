import React from 'react';
import styled from 'styled-components';

const TemplatesInFolder = ({ templates, selectedFolder }) => {

    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const rendering = () => {

        const selectedFolderTemplates = templates.filter(template => template.folder === selectedFolder);

        return (
            <TemplatesInFolderSC>
                <div className='templates'>
                    {selectedFolderTemplates.map(template => {
                        return (
                            <div key={template._id} className='template-name'>
                                {capitalizeFirstLetter(template.title)}
                            </div>
                        )
                    })}
                </div>
            </TemplatesInFolderSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TemplatesInFolderSC = styled.div`
    display : flex; 
    flex-direction : column;
    padding : 10px;
    
    .template-name {
        padding : 5px;

    }
`

export default TemplatesInFolder