import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const Header = ({ closeFunction }) => {

    const rendering = () => {
        return (
            <HeaderSC>
                <Button 
                    variant='outlined' 
                    color='primary'
                    onClick={closeFunction}
                >Close</Button>    
            </HeaderSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const HeaderSC = styled.div`
    display : flex; 
    justify-content : flex-end;
    border-bottom : 1px solid #e0e0e0;
    padding : 15px;
`

export default Header