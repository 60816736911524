export const tempCert = {
    _id: "677d39ebbd65b0d4d70aff6f",
    image: "733420df525a45acb491fc7026904947628e52fe445b4cc666097e41748a013c",
    trainingName: "Power BI Advanced",
    certificateName: "PowerBI advanced",
    certificateDate: "2025-01-07T14:27:55.879Z",
    user: {
        userEmail: "maxim.polderman@gmail.com",
        firstName: "Maxim",
        lastName: "Polderman",
        _id: "677d39ebbd65b0d4d70aff70",
        createdAt: "2025-01-07T14:27:55.877Z",
        updatedAt: "2025-01-07T14:27:55.877Z"
    },
    instanceId: "67668ff613fa722e37530d59",
    createdAt: "2025-01-07T14:27:55.877Z",
    updatedAt: "2025-01-07T14:27:55.877Z",
    __v: 0,
    imageUrl: "https://mp-trainingmanager-bucket.s3.us-east-1.amazonaws.com/733420df525a45acb491fc7026904947628e52fe445b4cc666097e41748a013c?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAY3ESOJ6UIRJ3BMSJ%2F20250113%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20250113T132550Z&X-Amz-Expires=3600&X-Amz-Signature=49950ecf03f7f227dc631e71a666bf4277bdedb5e7ff172d288121badb61157d&X-Amz-SignedHeaders=host&x-id=GetObject"
}