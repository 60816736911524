import React from 'react';
import styled from 'styled-components';
import { SurveyContext } from './FillSurveyLink';

const SurveyHeader  = () => {

    const { state, training, instance, trainer } = React.useContext(SurveyContext);

    const rendering = () => {
        return (
            <SurveyHeaderSC>
                <div className='left'>
                    <div className='logo-container'>
                        <img src='/holli-logo.png' alt='logo' className='holli-logo'/>
                    </div>
                    <div className='title-container'>
                        <p>{training.name}</p>
                    </div>
                </div>
                <div className='trainer-container'>
                    <p>{trainer.firstName}</p>
                    <div className='trainer-image' style={{backgroundImage : `url(${trainer.imageUrl})`}}></div>
                </div>
            </SurveyHeaderSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SurveyHeaderSC = styled.div`
    display : flex; 
    justify-content : space-between;
    align-items : center;
    background-color : white;
    height : 80px;
    border-radius : 10px;

    .left {
        display : flex;

        .logo-container {
            margin-left : 20px;

            & img {
                max-height : 50px;
            }
        }

        .title-container {
            margin-left : 20px;
            font-size : 1.5rem;
            align-items : center;
            justify-content : center;
            display : flex;
            flex-direction : column;
            color : #3C424D;
        }
    }

    .trainer-container {
        margin-right : 20px;
        font-size : 1.5rem;
        color : #3C424D;
        display : flex;
        align-items : center;
        
        &>p {
            margin-right : 10px;
            font-size : 16px;
        }

        .trainer-image {
            min-width : 50px;
            min-height : 50px;
            border-radius : 50%;
            background-size : cover;
            background-position : center;
            background-color : yellow;
        }
    }

    @media (max-width: 600px) {
        height : 60px;
        .left {
            .logo-container {
                & img {
                    max-height : 40px;
                }
            }
            .title-container {
                font-size : 1rem;
            }
        }
        .trainer-container {
            font-size : 1rem;
            &>p {
                font-size : 14px;
            }
            .trainer-image {
                min-width : 40px;
                min-height : 40px;
            }
        }
    }
`

export default SurveyHeader