import Axios from 'axios';
import ReactDOMServer, { renderToStaticMarkup } from 'react-dom/server';
import { renderToString } from 'react-dom/server';
import ssrPrepass from "react-ssr-prepass";
import React from 'react';
import styled from 'styled-components';
import { backEndAdress } from '../../variables/back-endAdress';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { tempCert } from './certTemp';

const PublicCertificate = () => {

    const certificateIdFromUrl = window.location.href.split('/')[window.location.href.split('/').length - 1];
    const [certificate, setCertificate] = React.useState(tempCert);
    const [loadingCertificate, setLoadingCertificate] = React.useState(true);   

    // React.useLayoutEffect(() => {
    //     const getCertificate = async () => {
    //         try {
    //             const certificate = await Axios.get(`${backEndAdress}/api/certificates/public/${certificateIdFromUrl}`);
    //             setCertificate(certificate.data);
    //             setLoadingCertificate(false);
    //         } catch (error) {
    //             console.log('error : ', error); 
    //             setLoadingCertificate(false);
    //         }
    //     }

    //     getCertificate();



    // }, []);

    const shareOnLinkedIn = () => {

        const certificateUrl = window.location.href;
        const predefinedText = `Proud to announce that I have just completed the training ${certificate.trainingName}!`;

        const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(certificateUrl)}`;
        const postText = `${predefinedText}\n\nCheck out my certificate: ${certificateUrl}`;
        const fullUrl = `${linkedInShareUrl}&text=${encodeURIComponent(postText)}`;
        window.open(fullUrl, "_blank", "noopener,noreferrer");
    };

    const certificateTitle = `${certificate?.trainingName}`;
    const certificateDescription = `Certificate of completion for ${certificate?.trainingName} by ${certificate?.user.firstName} ${certificate?.user.lastName}`;
    const certificateImage = certificate?.imageUrl;
    const certificateUrl = window.location.href;


    const rendering = () => {
        return (
            certificate &&
            <PublicCertificateSC className='public-certificate-component'>
                <Helmet>
                    <title>{certificate?.trainingName}</title>
                    <meta name="og:title" content={certificateTitle} />
                    <meta name="og:description" content={certificateDescription} />
                    <meta name="og:image" content={certificateImage} />
                    <meta name="og:url" content={certificateUrl} />
                </Helmet>
                <div className='container'>
                    <img src={certificate?.imageUrl} alt='certificate'/>
                
                    <div className='certificate-info'>
                        <h1>{certificate?.user.firstName} {certificate?.user.lastName}</h1>
                        <h2>Succesfully completed the course : {certificate.trainingName} on {moment(certificate?.createdAt).format('DD/MM/YYYY')}</h2>

                        <button
                            onClick={shareOnLinkedIn}
                        >Share on linkedin</button>
                    </div>
                </div>

            </PublicCertificateSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const PublicCertificateSC = styled.div`
    min-width : 100vw;
    min-height : 100vh;
    max-width : 100vw;
    max-height : 100vh;
    display : flex;
    flex-direction : column;
    justify-content : center;
    background-color : #f5f5f5;

    .container {
        min-height : 400px;
        min-width : 800px;
        max-width:  800px;
        max-height : 400px;
        background-color : white;
        margin : 0 auto;
        display : flex;

        img {
            margin : 25px;
            width : 350px;
            height : 350px;
        }

        .certificate-info {
            display : flex;
            flex-direction : column;
            justify-content : center;
            margin : 25px;

            h1 {
                font-size : 2rem;
            }

            h2 {
                font-size : 1rem;
                color : black;
                margin-bottom : 25px;
            }

            button {
                background-color : #0073b1;
                padding : 15px;
                color : white;
                border : none;
                cursor : pointer;
                border-radius : 5px;
            }
        }

    }

    @media (max-width : 800px) {
        .container {
            min-width : 90vw;
            max-width : 90vw;
            margin : 5vw;
            max-height : 90vh;
            min-height : 90vh;
            display : flex; 
            flex-direction : column;
            align-items : center;

            img {
                max-width : 95%;
                min-width : 95%;
                margin : 0;
            }
        }
    }
`

async function serverRender() {
    const appElement = <PublicCertificate />;
    const html = renderToStaticMarkup(appElement);
    return html;
  }
  
  serverRender().then((html) => {
    console.log(html);
  });

export default PublicCertificate