import React from 'react';
import styled from 'styled-components';
import { IndividualTrainingContext } from '../IndividiualTraining';
import AddOrEditTraining from '../../addOrEditTrainingComponent/AddOrEditTraining';
import { AppContext } from '../../../App';

const EditTraining = ({ state, setState, editExistingTraining, setReRender}) => {



    const { appState } = React.useContext(AppContext);
    // const { setEditMode } = React.useContext(IndividualTrainingContext);

    const rendering = () => {
        return (
            <EditTrainingSC>
                <AddOrEditTraining
                    appState={appState}
                    state={state}
                    setState={setState}
                    trainingToEdit={state.trainingToEdit}
                    closeWindowFunction={() => setState({...state, trainingToEdit : null})}
                    editExistingTraining={editExistingTraining}
                    setReRender={setReRender}
                />
            </EditTrainingSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const EditTrainingSC = styled.div`
    flex : 1;
    overflow: hidden;
    display : flex;
`

export default EditTraining