import React from 'react';
import styled from 'styled-components';
import ActionItem from './ActionItem';
import GradingIcon from '@mui/icons-material/Grading';
import LinkIcon from '@mui/icons-material/Link';
import EmailIcon from '@mui/icons-material/Email';
import SignupLinkModal from './SignupLinkModal';
import { IndividualSessionContext } from '../../IndividualSession';
import UICard from '../../../GeneralComponents/UIContainer/UICard';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
import MailAllUsers from '../MailAllUsers/MailAllUsers';
const Actions = ({ individualSession }) => {

    const { toggleSurveyModal } = React.useContext(IndividualSessionContext);

    const [signupLinkModal, setSignupLinkModal] = React.useState(false);
    const [mailAllUsersModal, setMailAllUsersModal] = React.useState(false);

    const rendering = () => {
        return (
            <UICard styleTags={{ margin : "20px", marginTop : 0, marginBottom : 0}}>
                <ActionsSC>

                    {signupLinkModal && <SignupLinkModal 
                        individualSession={individualSession}
                        setSignupLinkModal={setSignupLinkModal}    
                    />}

                    {signupLinkModal && <SignupLinkModal 
                        individualSession={individualSession}
                        setSignupLinkModal={setSignupLinkModal}    
                    />}

                    {mailAllUsersModal && <MailAllUsers 
                        individualSession={individualSession}
                        setMailAllUsersModal={setMailAllUsersModal}
                    />}

                        <div className='title-component'>
                            <MilitaryTechOutlinedIcon sx={{ stroke : 'white', strokeWidth : 1}}/>
                            <h1 className='card-title'>Actions</h1>
                        </div>

                    <div className='action-item' onClick={() => toggleSurveyModal(true)}>
                        <GradingIcon />
                        <div className='action-info' style={{display: 'flex', flexDirection: 'column'}}>
                            <h1 className='action-title'>Send out survey</h1>
                            <h3 className='action-description'>Send out survey to attendees</h3>
                        </div>
                    </div>
                    <div className='action-item' onClick={() => setSignupLinkModal(true)}>
                        <LinkIcon />
                        <div className='action-info' style={{display: 'flex', flexDirection: 'column'}}>
                            <h1 className='action-title'>Sign-up link</h1>
                            <h3 className='action-description'>Get the link where attendees can sign up for this instance</h3>
                        </div>
                    </div>
                    <div className='action-item' style={{ borderBottom : 0}} onClick={() => setMailAllUsersModal(true)}>
                        <EmailIcon />
                        <div className='action-info' style={{display: 'flex', flexDirection: 'column'}}>
                            <h1 className='action-title'>Mail all users</h1>
                            <h3 className='action-description'>Send out an e-mail to all users that signed up</h3>
                        </div>
                    </div>
                </ActionsSC>
            </UICard>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const ActionsSC = styled.div`
    background-color : white;
    border-radius : 5px;


    .action-item {
        display : flex; 
        align-items : center;
        border-bottom : 1px solid lightgrey;
        padding : 15px;

        &:hover {
            background-color : #F5F5F5;
            cursor : pointer;
        }

        svg {
            color : #737373;
            margin-right : 25px;
            margin-left : 10px
        }
    }


`

export default Actions